import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IconproduitFabriqueComponent} from './icon/iconproduit/icon-produitfab.component';
import {ViewmodeComponent} from './viewmode/viewmode.component';
import {SiteComponent} from './site/site.component';
import {CellButtonComponent} from './grid/cell-button/cell-button.component';
import {DndModule} from 'ng2-dnd';
import {LoginComponent} from './login/login.component';
import {YoUserHasRightDirective} from '../core/services/security/yo-userhasright.directive';
import {CanDeactivateGuard} from './guards/can-deactivate.guard';
import {HttpClientModule} from '@angular/common/http';
import {DraggableDirective} from './ui/draggable.directive';
import {DroppableDirective} from './ui/droppable.directive';
import {DragService} from './ui/drag.service';
import {FamilleGemrcnComponent} from './icon/famille-gemrcn/famille-gemrcn.component';
import {CouleurComponent} from './ui/couleur/couleur.component';
import {RegleGemrcnComponent} from './ui/regle-gemrcn/regle-gemrcn.component';
import {HelpComponent} from './ui/help/help.component';
import {GraphComponent} from './d3/visuals/graph/graph.component';
import {SHARED_VISUALS} from './d3/visuals/shared';
import {D3_DIRECTIVES} from '../core/d3/directives';
import {PreferencesDialogEditComponent} from './ui/preferences-dialog-edit/preferences-dialog-edit.component';
import {MotDePasseOublieComponent} from './mot-de-passe-oublie/mot-de-passe-oublie.component';
import {UtilisateurAuthComponent} from './utilisateurAuth/utilisateur-auth.component';
import {DynamicFormBsComponent} from './ui/generic-forms/bootstrap/dynamic-form-bs/dynamic-form-bs.component';
import {DynamicFormFieldBsComponent} from './ui/generic-forms/bootstrap/dynamic-form-field-bs/dynamic-form-field-bs.component';
import {TreeModule} from 'primeng/tree';
import {CommonModule} from '@angular/common';
import {TableModule} from 'primeng/table';
import {DynamicFormFieldErrorBsComponent} from './ui/generic-forms/bootstrap/dynamic-form-field-error-bs/dynamic-form-field-error-bs.component';
import {ControlErrorComponent} from './ui/control-error/control-error.component';
import {DateDispoComponent} from './ui/calendar/date-dispo/date-dispo.component';
import {ImgEntityComponent} from './ui/generic-datagrid/img-entity/img-entity.component';
import {PrimengEditorHeaderComponent} from './primeng-editor-header/primeng-editor-header.component';
import {DynamicFormFieldMessageBsComponent} from './ui/generic-forms/bootstrap/dynamic-form-field-message-bs/dynamic-form-field-message-bs.component';
import {BaseProductionComponent} from './base-production/base-production.component';
import {StatutsReceptionComponent} from './reception/statuts-reception/statuts-reception.component';
import {LitigeWarningComponent} from './reception/litige-warning/litige-warning.component';
import {CheckComponent} from './ui/check/check.component';
import {BlacklistInputDirective} from './ui/blacklist-input.directive';
import {FocusDirective} from './ui/focus.directive';
// @ts-ignore
import {RouterModule} from '@angular/router';
import {FormComponent} from './ui/generic-forms/bootstrap/form/form.component';
import {
  DxCheckBoxModule,
  DxDataGridModule,
  DxLoadIndicatorModule,
  DxPopupModule, DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule, DxTabPanelModule,
  DxToolbarModule, DxTooltipModule,
  DxTreeListModule,
  DxLookupModule,
  DxTagBoxModule,
  DxDateRangeBoxModule, DxListModule,
} from 'devextreme-angular';
import {ErreurFonctionnelleService} from '../core/services/technique/erreur-fonctionnelle.service';
import {InfoLotmarcheArticleComponent} from './marches/info-lotmarche-article/info-lotmarche-article.component';
import {LegendGridDevExtremeComponent} from './ui/legend-grid-dev-extreme/legend-grid-dev-extreme.component';
import {LeftAppMenuComponent} from './ui/left-app-menu/left-app-menu.component';
import {BreadcrumbComponent} from './ui/breadcrumb/breadcrumb.component';
import {DxPopoverModule} from 'devextreme-angular/ui/popover';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {CheckboxModule} from 'primeng/checkbox';
import {EditorModule} from 'primeng/editor';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {PanelModule} from 'primeng/panel';
import {MenuModule} from 'primeng/menu';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {InfosVersionComponent} from './ui/infos-version/infos-version.component';
import {PlanningEquipesComponent} from './ui/gestion-unitesproduction/planning-equipes/planning-equipes.component';
import {JoursDecalageComponent} from './ui/gestion-unitesproduction/planning-equipes/jours-decalage/jours-decalage.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {GrilleRemplacerArticleComponent} from "./article/grille-remplacer-article/grille-remplacer-article.component";
import {MarkdownModule} from "ngx-markdown";
import {FallbackDirective} from "./ui/fallback.directive";
import {MessageService} from "primeng/api";
import {TextMessageService} from "./ui/text-messages/textMessage.service";
import {DropMenuComponent} from "./ui/drop-menu/drop-menu.component";
import {ButtonComponent} from "./ui/button/button.component";
import {LookUpComponent} from "./ui/lookup/lookup.component";
import {TagBoxComponent} from "./ui/tag-box/tag-box.component";
import {DevextremeDateDispoComponent} from "./ui/calendar/devextreme-date-dispo/devextreme-date-dispo.component";
import {CommandeFournisseurDateFilter} from "./ui/commande-fournisseur-date-filter/commande-fournisseur-date-filter";
import {OrigineCarneComponent} from "./ui/origine-carne/origine-carne.component";
import {ImageComponent} from "./ui/image/image.component";
import {ImageListPopupComponent} from "./ui/image/list/popup/image-list-popup.component";
import { DialogMailHistoComponent } from './ui/dialog/email-histo/dialog-mail-histo.component';
import {EmailHistoButtonComponent} from "./ui/button/email-histo/email-histo-button.component";
import {ListComponent} from "./ui/list/list.component";

@NgModule({
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    DialogModule,
    CalendarModule,
    DropdownModule,
    SelectButtonModule,
    MultiSelectModule,
    CheckboxModule,
    EditorModule,
    TableModule,
    OverlayPanelModule,
    ScrollPanelModule,
    CardModule,
    ListboxModule,
    PanelModule,
    DxDataGridModule,
    DxTreeListModule,
    MenuModule,
    TieredMenuModule,
    DxPopoverModule,
    InputTextModule,
    RadioButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    MarkdownModule,
    DxLoadIndicatorModule,
    DxPopupModule,
    DxScrollViewModule,
    DxToolbarModule,
    DxTabPanelModule,
    DxRadioGroupModule,
    DxTooltipModule,
    DxLookupModule,
    DxTagBoxModule,
    DxDateRangeBoxModule,
    DxListModule,
  ],
    declarations: [
        IconproduitFabriqueComponent,
        ViewmodeComponent,
        SiteComponent,
        CellButtonComponent,
        ButtonComponent,
        EmailHistoButtonComponent,
        LookUpComponent,
        TagBoxComponent,
        LoginComponent,
        YoUserHasRightDirective,
        DraggableDirective,
        DroppableDirective,
        FamilleGemrcnComponent,
        CouleurComponent,
        RegleGemrcnComponent,
        HelpComponent,
        ...SHARED_VISUALS,
        ...D3_DIRECTIVES,
        GraphComponent,
        PreferencesDialogEditComponent,
        MotDePasseOublieComponent,
        UtilisateurAuthComponent,
        DynamicFormBsComponent,
        DynamicFormFieldBsComponent,
        DynamicFormFieldErrorBsComponent,
        ControlErrorComponent,
        DateDispoComponent,
        ImgEntityComponent,
        PrimengEditorHeaderComponent,
        DynamicFormFieldMessageBsComponent,
        BaseProductionComponent,
        StatutsReceptionComponent,
        LitigeWarningComponent,
        CheckComponent,
        BlacklistInputDirective,
        FocusDirective,
        FallbackDirective,
        FormComponent,
        InfoLotmarcheArticleComponent,
        LegendGridDevExtremeComponent,
        LeftAppMenuComponent,
        BreadcrumbComponent,
        InfosVersionComponent,
        PlanningEquipesComponent,
        JoursDecalageComponent,
        GrilleRemplacerArticleComponent,
        DropMenuComponent,
        DevextremeDateDispoComponent,
        CommandeFournisseurDateFilter,
        OrigineCarneComponent,
        ImageComponent,
        ImageListPopupComponent,
        DialogMailHistoComponent,
        ListComponent
    ],

    exports: [

        // SPECIFIC
        DndModule,
        IconproduitFabriqueComponent,
        ViewmodeComponent,
        SiteComponent,
        CellButtonComponent,
        LookUpComponent,
        TagBoxComponent,
        ButtonComponent,
        LoginComponent,
        YoUserHasRightDirective,
        DraggableDirective,
        DraggableDirective,
        DroppableDirective,
        FamilleGemrcnComponent,
        TreeModule,
        CouleurComponent,
        RegleGemrcnComponent,
        HelpComponent,
        ColorPickerModule,
        ...SHARED_VISUALS,
        ...D3_DIRECTIVES,
        GraphComponent,
        PreferencesDialogEditComponent,
        MotDePasseOublieComponent,
        UtilisateurAuthComponent,
        DynamicFormFieldBsComponent,
        DynamicFormBsComponent,
        ControlErrorComponent,
        DateDispoComponent,

        ImgEntityComponent,
        PrimengEditorHeaderComponent, StatutsReceptionComponent, LitigeWarningComponent, CheckComponent, BlacklistInputDirective,
        FocusDirective,

        FormComponent,
        InfoLotmarcheArticleComponent,
        LegendGridDevExtremeComponent,
        LeftAppMenuComponent, BreadcrumbComponent, PlanningEquipesComponent,
        GrilleRemplacerArticleComponent,
        DropMenuComponent, DevextremeDateDispoComponent, CommandeFournisseurDateFilter,
        OrigineCarneComponent,

        ImageComponent,
        ImageListPopupComponent,
        DialogMailHistoComponent, EmailHistoButtonComponent, ListComponent
    ],
  entryComponents: [
    PreferencesDialogEditComponent,
  ],
  providers: [
    MessageService,
    TextMessageService,
    ErreurFonctionnelleService
  ]
})
export class SharedModule {


  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CanDeactivateGuard,
        DragService
      ]
    };
  }

}
