// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10685(b40515c7d1)-C04/10/2024-17:22:47-B04/10/2024-17:33:35' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10685(b40515c7d1)-C04/10/2024-17:22:47-B04/10/2024-17:33:35",
  branch: "master",
  latestTag: "6.1",
  revCount: "10685",
  shortHash: "b40515c7d1",
  longHash: "b40515c7d1323bef74039c79eada6ab1e5348e56",
  dateCommit: "04/10/2024-17:22:47",
  dateBuild: "04/10/2024-17:33:35",
  buildType: "Ansible",
  } ;
